export enum AccountingTypes {
    ModifiedAccrual = 'ModifiedAccrual',
    FullAccrual = 'FullAccrual'
}

export class AccountingTypeNames {
    [accountingType: string]: string;

    constructor() {
        this[AccountingTypes.ModifiedAccrual] = 'Modified Accrual';
        this[AccountingTypes.FullAccrual]= 'Full Accrual';
    }
}