import { Enum } from './enum';
import { CharterReviewStatuses } from './charter-review-statuses';

export class CharterReviewStatusNames {
  [status: string]: string;

  constructor() {
    this[CharterReviewStatuses.Unknown] = 'Unknown';
    this[CharterReviewStatuses.Pending] = 'Pending';
    this[CharterReviewStatuses.Approved] = 'Approved';
    this[CharterReviewStatuses.DidNotReport] = 'Did Not Report';
    this[CharterReviewStatuses.PendingConsultant] = 'Pending Consultant';
  }
}
