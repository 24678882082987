import { EventEmitter, Injectable, Output } from '@angular/core';
import { MenuItem, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { Coversheet60kAvailability } from '../coversheet60k/models/coversheet60k-availability.model';
import { ColumnCodes } from '../shared/column-codes';
import { dateConvertFromUTC, dateConvertToUTC } from '../shared/date-operations';
import { FormGroupIds } from '../shared/form-group-ids';
import { FormGroup, FormResponse } from '../shared/form.model';
import { SubmissionItem, UpdateSubmissionOnFormSaveResponse } from '../shared/submission.model';

@Injectable()
export class SubmissionContext {
  title: string;
  formId: string;
  instanceId: string;
  helpUrlPath: string;
  formLastSaved: Date;
  showFormLastSaved: boolean;

  submission: SubmissionItem;
  coversheet60kAvailability: Coversheet60kAvailability;

  get assignedTo(): string {
    return this.submission?.assignedTo && this.submission.assignedToUser
      ? this.submission.assignedToUser.firstName + ' ' + this.submission.assignedToUser.lastName
      : 'Not Assigned';
  }

  isDirty = false;
  disableFormSave: boolean;
  isFormEdited = false;
  isReadyForReview = false;
  isTableOfContents = false;
  isSEMTypeForm = false;
  isPickupUAListAvailable = false;

  private leaveFormConfirmDialogResultSource = new Subject<boolean>();

  leaveFormConfirmDialogResult = this.leaveFormConfirmDialogResultSource.asObservable();

  setLeaveFormConfirmDialogResult(okToLeaveForm: boolean): void {
    this.leaveFormConfirmDialogResultSource.next(okToLeaveForm);
  }

  setCoversheet60kAvailability(coversheet60kAvailability: Coversheet60kAvailability): void {
    this.coversheet60kAvailability = coversheet60kAvailability;
  }

  updateOnFormCellEdit(formResponse: FormResponse): void {
    this.formId = formResponse.form.instanceId;
    if (formResponse.form.lastSaved) {
      this.formLastSaved = dateConvertFromUTC(formResponse.form.lastSaved);
      this.showFormLastSaved = true;
    }
    this.formLastSaved = dateConvertFromUTC(formResponse.form.lastSaved);
    this.submission = formResponse.submission;

    this.isFormEdited = !formResponse.form.editableCellsEmpty;
    this.disableFormSave = formResponse.form.disableFormSave;
    this.isTableOfContents = this.formId === 'TC' || this.formId === 'TCI';
  }

  updateOnFormSave(updateSubmissionOnFormSaveResponse: UpdateSubmissionOnFormSaveResponse): void {
    this.formLastSaved = dateConvertToUTC(updateSubmissionOnFormSaveResponse.formLastSaved);
    this.showFormLastSaved = true;
    this.submission = updateSubmissionOnFormSaveResponse.submission;
    this.isDirty = false;
  }

  leaveForm(): void {
    this.formId = undefined;
    this.instanceId = undefined;
    this.formLastSaved = undefined;
    this.showFormLastSaved = undefined;
    this.coversheet60kAvailability = undefined;
  }

  formGroups: Array<FormGroup>;

  selectedColumnCode: SelectItem<ColumnCodes>;

  formGroupMenuItems: Array<MenuItem>;
  coverSheetMenuItems: Array<MenuItem>;
  reportMenuItems: Array<MenuItem>;

  hideHeader = false;
  hideActionsPanel = false;
  showLockAction = false;
  hideExcelExportAction = false;

  @Output()
  selectedColumnCodeEvent = new EventEmitter<ColumnCodes>();

  @Output()
  printPreviewEvent = new EventEmitter();

  @Output()
  printTableOfContentsEvent = new EventEmitter();

  @Output()
  exportExcelEvent = new EventEmitter();

  @Output()
  exportDATEvent = new EventEmitter();

  @Output()
  saveEvent = new EventEmitter();

  @Output()
  clearEvent = new EventEmitter();

  @Output()
  showCommentsEvent = new EventEmitter<boolean>();

  @Output()
  officialValidationEvent = new EventEmitter();

  @Output()
  toggleFormLockEvent = new EventEmitter();

  @Output()
  changeReadyForReviewEvent = new EventEmitter();

  @Output()
  showPickupListUASubmissionsEvent = new EventEmitter();

  @Output()
  downloadReportEvent = new EventEmitter<string>();

  @Output()
  onSubmissionSkipValidationChanged = new EventEmitter<boolean>();

  buildRouterLink(...routeParams: Array<string>): Array<string> {
    return [
      '/submission',
      this.submission.number,
      ...routeParams
    ];
  }

  generateFormsMenu(formGroups: Array<FormGroup>): void {
    this.formGroups = formGroups;
    this.formGroupMenuItems = undefined;
    this.reportMenuItems = undefined;
    this.coverSheetMenuItems = undefined;

    this.formGroupMenuItems = formGroups.filter(t =>
      t.id === FormGroupIds.Funds ||
      t.id === FormGroupIds.Supplementals).map(
        formGroup => {
          const formGroupName = formGroup.name.toLowerCase();
          return {
            id: `navigation_left_menu_forms_${formGroupName}`,
            icon: 'navigation_left pi pi-file-excel',
            label: `${formGroup.name}`,
            items: formGroup.forms.map(
              form => {
                const formName = form.name.toLowerCase();
                return {
                  id: `navigation_left_menu_forms_${formGroupName}_${formName}`,
                  icon: 'navigation_left pi pi-file-excel',
                  label: `${form.name} - ${form.title}`,
                  routerLink: formGroupName === formName
                    ? this.buildRouterLink('forms', formGroupName)
                    : this.buildRouterLink('forms', formGroupName, formName)
                };
              }
            )
          };
        }
      );

    const criteriaAndStandardsGroup = formGroups.find(t => t.id === FormGroupIds.CriteriaAndStandards);
    if (criteriaAndStandardsGroup && criteriaAndStandardsGroup.forms && criteriaAndStandardsGroup.forms.length > 0)
      this.formGroupMenuItems.push(...criteriaAndStandardsGroup.forms.map(
        form => ({
          id: 'navigation_left_menu_forms_criteriaandstandards',
          icon: 'navigation_left pi pi-file',
          label: `${form.name} - ${form.title}`,
          routerLink: this.buildRouterLink(
            'forms',
            criteriaAndStandardsGroup.name.toLowerCase(),
            form.name.toLowerCase())
        })));

    const coverSheetGroup = formGroups.find(t => t.id === FormGroupIds.CoverSheets);
    if (coverSheetGroup && coverSheetGroup.forms && coverSheetGroup.forms.length > 0)
      this.coverSheetMenuItems = coverSheetGroup.forms.map(
        form => ({
          id: `navigation_left_menu_coversheets_${form.id.toLowerCase()}`,
          icon: 'navigation_left pi pi-file',
          label: `${form.name} - ${form.title}`,
          routerLink: this.buildRouterLink(
            coverSheetGroup.name.toLowerCase(),
            form.name.toLowerCase())
        }));

    const reportGroup = formGroups.find(t => t.id === FormGroupIds.Reports);
    if (reportGroup && reportGroup.forms && reportGroup.forms.length > 0)
      this.reportMenuItems = reportGroup.forms.map(
        form => ({
          id: `navigation_left_menu_reports_${form.id.toLowerCase()}`,
          icon: 'navigation_left pi pi-file-pdf',
          label: `${form.name} - ${form.title}`,
          routerLink: this.buildRouterLink(
            reportGroup.name.toLowerCase(),
            form.name.toLowerCase())
        }));
  }

  set currentColumnCodeSessionValue(value: string) {
    if (value) {
      sessionStorage.setItem(`id_${this.submission.id}`, value);
    }
  }

  get currentColumnCodeSessionValue(): string {
    return sessionStorage.getItem(`id_${this.submission.id}`);
  }

  getSelectedColumnCode(columnCode: string, columnCodeChanged: boolean) {
    var currentColumnCode = columnCode;
    if (this.currentColumnCodeSessionValue) {
      if (this.selectedColumnCode)
        currentColumnCode = this.currentColumnCodeSessionValue = columnCodeChanged ? columnCode : this.currentColumnCodeSessionValue;
      else
        currentColumnCode = this.currentColumnCodeSessionValue;
    } else
      this.currentColumnCodeSessionValue = currentColumnCode;
  }
}
