import { Params } from '@angular/router';
import { CharterTypes } from './charter-types';
import { ColumnCodes } from './column-codes';
import { DataTypes } from './data-types';
import { DatasetTypes } from './dataset-types';
import { EntityTypes } from './entity-types';
import { EvaluationCriteriaBase } from './forms/evaluation-criteria-base';
import { IFCResult } from './ifc-result.model';
import { ReportingPeriods } from './reporting-periods';
import { SubmissionItem } from './submission.model';
import { SystemVersion } from './system-version.model';

export enum VerticalAlignments {
  Top = 'Top',
  Bottom = 'Bottom',
  Middle = 'Middle'
}

export enum HorizontalAlignments {
  Left = 'Left',
  Right = 'Right',
  Center = 'Center'
}

export class TypedValue {
  dataType: DataTypes;
  value: boolean | Date | number | string;

  constructor(init?: Partial<TypedValue>) {
    Object.assign(this, init);
  }

  copyTo(target: TypedValue): void {
    target.value = this.value;
    target.dataType = this.dataType;
  }

  static isValueNull(typedValue: TypedValue): boolean {
    return typedValue === undefined ||
      typedValue === null ||
      typedValue.value === undefined ||
      typedValue.value === null ||
      typedValue.value === '';
  }
}

export enum CellEditorTypes {
  Boolean = 'Boolean',
  DateTime = 'DateTime',
  String = 'String',
  ResetAction = 'ResetAction'
}

export abstract class CellEditorOptions {
  type: CellEditorTypes;
}

export class CellBooleanEditorOptions extends CellEditorOptions {
  groupName: string;
}

export class CellDateTimeEditorOptions extends CellEditorOptions {
  showTime: boolean;
  timeOnly: boolean;
}

export class CellStringEditorOptions extends CellEditorOptions {
  mask: string;
}

export class CellResetActionEditorOptions extends CellEditorOptions {
  caption: string;
  actionValue: TypedValue;
}

export class CellEditorState {
  edited: boolean;
}

export class FormCellValue {
  tabId: string;
  rowId: string;
  columnId: string;
  rowDefinitionId: string;
  columnDefinitionId: string;
  value: TypedValue;

  constructor(init?: Partial<FormCellValue>) {
    Object.assign(this, init);
  }
}

export class Cell {
  tabId: string;
  rowId: string;
  columnId: string;
  rowDefinitionId: string;
  columnDefinitionId: string;
  edit?: boolean;
  hidden?: boolean;
  shaded?: boolean;
  strong?: boolean;
  emphasis?: boolean;
  underline?: boolean;
  rowSpan?: number;
  columnSpan?: number;
  percentage?: boolean;
  verticalAlignment?: VerticalAlignments;
  horizontalAlignment?: HorizontalAlignments;
  value: TypedValue;
  editorDataType?: DataTypes;
  message: string;
  displayValue: string;
  editorOptions: CellEditorOptions;
  editorState: CellEditorState;
  elementCode: string;
  maxLength: number;
  hideMessage?: boolean;
  printLayout: PrintLayout;

  constructor(init?: Partial<Cell>) {
    Object.assign(this, init);
  }
}

export class Row {
  id: string;
  rowDefinitionId: string;
  tabId: string;
  indent?: number;
  value: TypedValue;
  cells: Array<Cell>;
  hidden?: boolean;
  editable?: boolean;
  deletable?: boolean;
  frozen?: boolean;
  printLayout: PrintLayout;

  constructor(init?: Partial<Row>) {
    Object.assign(this, init);
  }
}

export class Line {
  id: string;
  tabId: string;
  columnId: string;
  headerId: string;
  shaded?: boolean;
  strong?: boolean;
  emphasis?: boolean;
  underline?: boolean;
  text: string;
  message: string;
}

export class Header {
  id: string;
  tabId: string;
  columnId: string;
  rowSpan?: number;
  columnSpan?: number;
  verticalAlignment?: VerticalAlignments;
  horizontalAlignment?: HorizontalAlignments;
  lines: Array<Line>;
}

export class Column {
  id: string;
  columnDefinitionId: string;
  tabId: string;
  frozen?: boolean;
  value: TypedValue;
  headers: Array<Header>;
  printLayout: PrintLayout;
  left: string;
}

export class Section {
  id: string;
  anchor: string;
  caption: string;
  firstRowId: string;
  lastRowId: string;
}

export class Tab {
  id: string;
  ignoreIndents?: boolean;
  caption: string;
  rows: Array<Row>;
  columns: Array<Column>;
  sections: Array<Section>;
  headerIndices: Array<number>;
  printLayout: PrintLayout;
}

export class Variable {
  id: string;
  value: TypedValue;
}

export class PrintLayout {
  public borders: Array<Border>;
  public width: number;
}

export enum LayoutOrientation {
  Portrait = 'Portrait',
  Landscape = 'Landscape'
}

export class PagedLayout extends PrintLayout {
  public orientation: LayoutOrientation;
}

export enum Position {
  Top = 'Top',
  Left = 'Left',
  Right = 'Right',
  Bottom = 'Bottom'
}

export enum BorderType {
  Thin = 'Thin',
  Solid = 'Solid',
  Double = 'Double'
}

export class Border {
  public position: Position;
  public type: BorderType;
}

export class Form {
  id: string;
  version: number;
  groupId: string;
  instanceId: string;
  name: string;
  title: string;
  tabs: Array<Tab>;
  variables: Array<Variable>;
  ifcResults: Array<IFCResult>;
  editableCellsEmpty: boolean;
  dependentOnFormInstanceIds: Array<string>;
  formInstanceIdsDependentOnThis: Array<string>;
  lastSaved: Date;
  hasIFCErrors: boolean;
  hasNoNSIFCErrors: boolean;
  disableFormLock: boolean = false;
  ignoreFormLock: boolean = false;
  skipUpdate: boolean = false;
  elementCodesData: ElementCodeData[];
  disableFormSave: boolean;
  printLayout: PrintLayout;

  constructor(init?: Partial<Form>) {
    Object.assign(this, init);
  }
}

export class FormGroup {
  id: string;
  name: string;
  title: string;
  forms: Array<Form>;

  constructor(init?: Partial<FormGroup>) {
    Object.assign(this, init);
  }
}

export class ResetActionEditorEvent {
  originTabId: string;
  originRowId: string;
  originCellId: string;
  actionValue: TypedValue;
}

export class FormResponse {
  form: Form;
  userDataState: UserDataState;
  systemVersion: SystemVersion;
  submission: SubmissionItem;
}

export class FormGroupsResponse {
  formGroups: Array<FormGroup>;
  systemVersion: SystemVersion;
}

export class ComputeFormGroupsRequest {
  submissionNumber: string;

  constructor(
    submissionNumber: string) {
    this.submissionNumber = submissionNumber;
  }
}

export class ComputeBlankFormGroupsRequest {
  versionInfo: Params;
  fullFiscalYear: string;
  entityType: EntityTypes;
  charterType?: CharterTypes;
  datasetType: DatasetTypes;
  reportingPeriod: ReportingPeriods;

  constructor(
    versionInfo: Params,
    fullFiscalYear: string,
    entityType: EntityTypes,
    charterType: CharterTypes | null = null,
    datasetType: DatasetTypes,
    reportingPeriod: ReportingPeriods) {
    this.fullFiscalYear = fullFiscalYear;
    this.entityType = entityType;
    this.charterType = charterType;
    this.datasetType = datasetType;
    this.reportingPeriod = reportingPeriod;
    this.versionInfo = versionInfo;
  }
}

export class ComputeBlankFormRequest extends ComputeBlankFormGroupsRequest {
  groupId: string;
  id: string;
  instanceId: string;
  criteria?: EvaluationCriteriaBase;

  constructor(
    versionInfo: Params,
    groupId: string,
    id: string,
    instanceId: string,
    fullFiscalYear: string,
    reportingPeriod: ReportingPeriods,
    entityType: EntityTypes,
    charterType: CharterTypes | null = null,
    datasetType: DatasetTypes,
    criteria: EvaluationCriteriaBase | null = null) {
    super(versionInfo, fullFiscalYear, entityType, charterType, datasetType, reportingPeriod);
    this.groupId = groupId;
    this.id = id;
    this.instanceId = instanceId || 'none';
    this.criteria = criteria;
  }
}

export class ComputeFormRequest extends ComputeFormGroupsRequest {
  versionInfo: Params;
  groupId: string;
  id: string;
  instanceId: string;
  criteria?: EvaluationCriteriaBase;

  constructor(
    versionInfo: Params,
    groupId: string,
    id: string,
    instanceId: string,
    submissionNumber: string,
    criteria: EvaluationCriteriaBase | null = null) {
    super(submissionNumber);
    this.versionInfo = versionInfo;
    this.groupId = groupId;
    this.id = id;
    this.instanceId = instanceId;
    this.criteria = criteria;
  }
}

export class UpdateFormRequest extends ComputeFormGroupsRequest {
  id: string;
  groupId: string;
  instanceId: string;
  versionInfo: Params;
  formCellValues: FormCellValue[];
  userDataState: UserDataState;
  criteria: EvaluationCriteriaBase;
  currentResetActionEditorEvent: ResetActionEditorEvent;

  constructor(
    groupId: string,
    id: string,
    instanceId: string,
    versionInfo: Params,
    formCellValues: FormCellValue[],
    submissionNumber: string,
    userDataState: UserDataState,
    criteria?: EvaluationCriteriaBase,
    currentResetActionEditorEvent?: ResetActionEditorEvent) {
    super(submissionNumber);
    this.id = id;
    this.groupId = groupId;
    this.instanceId = instanceId;
    this.versionInfo = versionInfo;
    this.formCellValues = formCellValues;
    this.userDataState = userDataState;
    this.criteria = criteria;
    this.currentResetActionEditorEvent = currentResetActionEditorEvent;
  }
}

export class UpdateBlankFormRequest extends ComputeBlankFormGroupsRequest {
  id: string;
  groupId: string;
  instanceId: string;
  versionInfo: Params;
  formCellValues: FormCellValue[];
  userDataState: UserDataState;
  criteria: EvaluationCriteriaBase;
  currentResetActionEditorEvent: ResetActionEditorEvent;

  constructor(
    groupId: string,
    id: string,
    instanceId: string,
    versionInfo: Params,
    formCellValues: FormCellValue[],
    entityType: EntityTypes,
    charterType: CharterTypes | null = null,
    datasetType: DatasetTypes,
    userDataState: UserDataState,
    fullFiscalYear: string,
    reportingPeriod: ReportingPeriods,
    criteria: EvaluationCriteriaBase,
    currentResetActionEditorEvent: ResetActionEditorEvent) {
    super(versionInfo, fullFiscalYear, entityType, charterType, datasetType, reportingPeriod);
    this.id = id;
    this.groupId = groupId;
    this.instanceId = instanceId;
    this.versionInfo = versionInfo;
    this.formCellValues = formCellValues;
    this.userDataState = userDataState;
    this.criteria = criteria;
    this.currentResetActionEditorEvent = currentResetActionEditorEvent;
  }
}

export class ElementCodeData {
  public constructor(
    public elementCodeName: string,
    public columnCodes: ColumnCodes[]) { }
}

export class AffectedIFC {
  public elementCodePrefix: string;
  public columnCodes: ColumnCodes[];
  public componentVersion: string;
}

export class UserDataState {
  public affectedColumnCodes: ColumnCodes[];
  public patch: string;
  public affectedIFCs: AffectedIFC[];
}
