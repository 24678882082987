export enum FundUsed {
  General = 'General',
  CharterSpecRevenue = 'CharterSpecRevenue',
  CharterEnterprise = 'CharterEnterprise'
}

export class FundUsedNames {
  [fund: string]: string;

  constructor() {
    this[FundUsed.General] = '01 General';
    this[FundUsed.CharterSpecRevenue] = '09 Charter Spec. Revenue';
    this[FundUsed.CharterEnterprise] = '62 Charter Enterprise';
  }
}