import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AppContext } from './app.context';
import { AppMessageService } from './services/app-message.service';
import { LocksService } from './services/locks.service';
import { dateConvertFromUTC } from './shared/date-operations';
import { LockData, LockFormRequest, LockTypes, SubmissionLockData } from './shared/lock.model';
import { MessageCodes } from './shared/message-codes';
import { NgMessage } from './shared/ng-message';
import { Roles } from './shared/roles';
import { StatusTypes } from './shared/status-types';
import { SubmissionContext } from './submission/submission.context';
import { RunModes } from './shared/runs-modes';

@Injectable()
export class LocksContext {
  private unknownErrorMessage: NgMessage = this.appContext.messages[MessageCodes.UnknownError];

  @Output()
  onLocksChanged = new EventEmitter();

  public busy: boolean = false;
  public showLocks: boolean;
  public submissionLockData: SubmissionLockData;
  public isSubmissionLockedByCurrentUser: boolean;

  constructor(
    private readonly router: Router,
    private readonly appContext: AppContext,
    private readonly locksService: LocksService,
    private readonly submissionContext: SubmissionContext,
    private readonly appMessageService: AppMessageService,
    private readonly confirmationService: ConfirmationService) {
  }

  public async displayLocks(submissionNumber: string, fetch: boolean = true): Promise<void> {
    this.appMessageService.clear();

    if (fetch)
      await this.fetchLocks(submissionNumber);

    if (this.submissionLockData.lockData?.length === 0) {
      this.appMessageService.add({ severity: 'info', summary: "Nothing is locked." });
      return;
    }

    this.showLocks = true;
  }

  public async fetchLocks(submissionNumber: string): Promise<void> {
    this.appMessageService.clear();

    const ngResponse = await this.locksService.fetch(submissionNumber);

    if (!ngResponse) {
      this.appMessageService.add({ severity: this.unknownErrorMessage.severityLevel.toLocaleString(), summary: this.unknownErrorMessage.text });
    } else if (ngResponse.status === StatusTypes.Success) {
      this.submissionLockData = ngResponse.response;
      this.handleLockChanged();
    } else {
      this.appMessageService.add({ severity: ngResponse.error.severityLevel.toLocaleString(), summary: ngResponse.error.failureReason });
    }
  }

  public async canEdit(submissionNumber: string, formInstanceId: string, ignoreFormLock: boolean = false): Promise<boolean> {
    if (ignoreFormLock) return true;
    await this.fetchLocks(submissionNumber);

    if (!this.isFormLockedByCurrentUser(formInstanceId) && !this.isSubmissionLockedByCurrentUser) {
      await this.displayLocks(submissionNumber, false);
      return false;
    }

    return true;
  }

  public async hasLocks(submissionNumber: string): Promise<boolean> {
    await this.fetchLocks(submissionNumber);
    return this.submissionLockData?.lockData.length > 0;
  }

  public async toggleSubmissionLock(submissionNumber: string): Promise<void> {
    this.showLocks = false;
    await this.fetchLocks(submissionNumber);

    if (this.isSubmissionLockedByCurrentUser)
      await this.confirmUnlockSubmission(submissionNumber, false);
    else
      await this.lockSubmission(submissionNumber);
  }

  public isFormLockedByCurrentUser(formInstanceId: string) {
    return !formInstanceId ? false : this.submissionLockData?.lockData?.some(t => t.formInstanceId === formInstanceId && t.isLockedByCurrentUser);
  }

  public async lockForm(submissionNumber: string, formInstanceId: string, relatedInstanceIds: string[]): Promise<void> {
    this.busy = true;
    this.showLocks = false;
    this.appMessageService.clear();

    const request = this.appContext.createRequest(new LockFormRequest(submissionNumber, formInstanceId, relatedInstanceIds));
    const ngResponse = await this.locksService.lockForm(request);

    if (!ngResponse)
      this.appMessageService.add({ severity: this.unknownErrorMessage.severityLevel.toLocaleString(), summary: this.unknownErrorMessage.text });
    else if (ngResponse.status === StatusTypes.Success) {
      this.router.navigated = false;
      this.router.navigate([this.router.url.split('?')[0]], { queryParamsHandling: 'preserve' }).then(() =>
        setTimeout(() => this.setFormLockStatusMessages(formInstanceId, ngResponse.response), 0));

      this.submissionLockData = ngResponse.response;
    } else
      this.appMessageService.add({ severity: ngResponse.error.severityLevel.toLocaleString(), summary: ngResponse.error.failureReason });

    this.handleLockChanged();
    this.busy = false;
  }

  public async unlockForm(submissionNumber: string, formInstanceId: string, unlockOverride: boolean): Promise<void> {
    this.busy = true;
    this.showLocks = false;
    this.appMessageService.clear();

    await this.fetchLocks(submissionNumber);

    const ngResponse = await this.locksService.unlockForm(submissionNumber, formInstanceId, unlockOverride, this.appContext.runMode, this.appContext.testRunId);

    if (!ngResponse)
      this.appMessageService.add({ severity: this.unknownErrorMessage.severityLevel.toLocaleString(), summary: this.unknownErrorMessage.text });
    else if (ngResponse.status === StatusTypes.Success) {
      if (ngResponse.response?.lockData.length > 0)
        this.setFormLockStatusMessages(formInstanceId, ngResponse.response, true);
      else
        this.appMessageService.add({ severity: 'success', summary: 'Form unlocked.' });

      this.submissionLockData = ngResponse.response;
    } else
      this.appMessageService.add({ severity: ngResponse.error.severityLevel.toLocaleString(), summary: ngResponse.error.failureReason });

    this.handleLockChanged();
    this.busy = false;
  }

  public async unlockAll(submissionNumber: string): Promise<void> {
    this.appMessageService.clear();
    this.busy = true;

    const ngResponse = await this.locksService.unlockAll(submissionNumber, this.appContext.runMode, this.appContext.testRunId);

    if (!ngResponse)
      this.appMessageService.add({ severity: this.unknownErrorMessage.severityLevel.toLocaleString(), summary: this.unknownErrorMessage.text });
    else if (ngResponse.status === StatusTypes.Success) {
      if (ngResponse.response?.lockData.length > 0)
        this.setSubmissionLockStatusMessages(ngResponse.response);
      else
        this.appMessageService.add({ severity: 'success', summary: 'Everything is unlocked.' });

      this.submissionLockData = ngResponse.response;
    } else
      this.appMessageService.add({ severity: ngResponse.error.severityLevel.toLocaleString(), summary: ngResponse.error.failureReason });

    this.handleLockChanged();
    this.busy = false;
  }

  public hasOverrideAccess() {
    return this.appContext.loggedInUser.defaultEntity.roles.some(t => t === Roles.Administration || t === Roles.UserManagement);
  }

  public async overrideUnlockSubmission(submissionNumber: string): Promise<void> {
    await this.confirmUnlockSubmission(submissionNumber, true);
  }

  private async lockSubmission(submissionNumber: string): Promise<void> {
    this.appMessageService.clear();
    this.busy = true;

    const ngResponse = await this.locksService.lockSubmission(submissionNumber);

    if (!ngResponse)
      this.appMessageService.add({ severity: this.unknownErrorMessage.severityLevel.toLocaleString(), summary: this.unknownErrorMessage.text });
    else if (ngResponse.status === StatusTypes.Success) {
      this.router.navigated = false;
      this.router.navigate([this.router.url.split('?')[0]], { queryParamsHandling: 'preserve' }).then(() =>
        setTimeout(() => { this.setSubmissionLockStatusMessages(ngResponse.response) }, 0));

      this.submissionLockData = ngResponse.response;
    } else
      this.appMessageService.add({ severity: ngResponse.error.severityLevel.toLocaleString(), summary: ngResponse.error.failureReason });

    this.handleLockChanged();
    this.busy = false;
  }

  private async confirmUnlockSubmission(submissionNumber: string, unlockOverride: boolean): Promise<void> {
    if (this.submissionContext?.formId || this.router.url.includes("explanations")) {
      this.confirmationService.confirm({
        header: 'Unlock Submission',
        message: 'Any unsaved changes will be discarded if you unlock the submission. Are you sure you want to do this?',
        accept: () => this.unlockSubmission(submissionNumber, unlockOverride).then()
      });
    } else
      return this.unlockSubmission(submissionNumber, unlockOverride);
  }

  private async unlockSubmission(submissionNumber: string, unlockOverride: boolean): Promise<void> {

    this.appMessageService.clear();

    this.busy = true;

    const ngResponse = await this.locksService.unlockSubmission(submissionNumber, unlockOverride, this.appContext.runMode, this.appContext.testRunId);

    if (!ngResponse)
      this.appMessageService.add({ severity: this.unknownErrorMessage.severityLevel.toLocaleString(), summary: this.unknownErrorMessage.text });
    else if (ngResponse.status === StatusTypes.Success) {
      if (ngResponse.response?.lockData.length > 0)
        this.setSubmissionLockStatusMessages(ngResponse.response);
      else
        this.appMessageService.add({ severity: 'success', summary: 'Submission unlocked.' });

      this.submissionLockData = ngResponse.response;
    } else
      this.appMessageService.add({ severity: ngResponse.error.severityLevel.toLocaleString(), summary: ngResponse.error.failureReason });

    this.handleLockChanged();
    this.busy = false;
  }

  private handleLockChanged() {
    this.isSubmissionLockedByCurrentUser =
      this.submissionLockData.lockData.some(t => t.lockType === LockTypes.Submission && t.isLockedByCurrentUser);

    this.onLocksChanged.emit();
  }

  private setSubmissionLockStatusMessages(submissionLockData: SubmissionLockData) {

    if (submissionLockData?.lockData.length === 0) {
      this.appMessageService.add({ severity: 'success', summary: "Nothing is locked." });
      return;
    }

    const submissionLock = submissionLockData.lockData.find(t => t.lockType == LockTypes.Submission);

    if (submissionLock) {
      if (submissionLock.lockedBy.email === this.appContext.loggedInUserEmail)
        this.appMessageService.add({ severity: "success", summary: "Submission locked." });
      else {
        const lockedDate = dateConvertFromUTC(submissionLock.locked).toLocaleDateString();
        this.appMessageService.add({
          severity: "error", summary: `Unable to lock the submission.<br />The submission is locked on
              ${lockedDate} by
              ${submissionLock.lockedBy.firstName}
              ${submissionLock.lockedBy.lastName}
              (${submissionLock.lockedBy.email}).`
        });
      }
    } else if (submissionLockData.lockData.length > 0) {
      let result = 'Unable to lock the submission. <br />';

      submissionLockData.lockData.filter(t => t.lockedBy.email !== this.appContext.loggedInUserEmail).forEach(lock => {
        result += this.createLockString(lock) + '<br />';
      });

      this.appMessageService.add({ severity: 'error', summary: result });
    }
  }

  private setFormLockStatusMessages(formInstanceId: string, submissionLockData: SubmissionLockData, unlock: boolean = false) {

    if (submissionLockData?.lockData.length === 0) {
      this.appMessageService.add({ severity: 'success', summary: "Nothing is locked." });
      return;
    }

    const submissionLock = submissionLockData.lockData.find(t => t.lockType == LockTypes.Submission);

    if (submissionLock) {
      if (submissionLock.lockedBy.email === this.appContext.loggedInUserEmail)
        this.appMessageService.add({ severity: "success", summary: "This submission is already locked by you." });
      else {
        const lockedDate = dateConvertFromUTC(submissionLock.locked).toLocaleDateString();
        this.appMessageService.add({
          severity: "error", summary: `Unable to lock the form.<br />The submission is locked on
              ${lockedDate} by
              ${submissionLock.lockedBy.firstName}
              ${submissionLock.lockedBy.lastName}
              (${submissionLock.lockedBy.email}).`
        });
      }
    } else if (submissionLockData.lockData.length > 0) {
      if (submissionLockData.lockData.some(t =>
        t.lockedBy.email === this.appContext.loggedInUserEmail && t.formInstanceId === formInstanceId))
        this.appMessageService.add({ severity: 'success', summary: 'Form locked.' });
      else if (unlock && !submissionLockData.lockData.some(t =>
        t.lockedBy.email === this.appContext.loggedInUserEmail && t.formInstanceId === formInstanceId))
        this.appMessageService.add({ severity: 'success', summary: 'Form unlocked.' });
      else {
        let result = 'Unable to lock the form. <br />';

        submissionLockData.lockData.filter(t => t.lockedBy.email !== this.appContext.loggedInUserEmail).forEach(lock =>
          result += this.createLockString(lock) + '<br />');

        this.appMessageService.add({ severity: 'error', summary: result });
      }
    }
  }

  private createLockString(lock: LockData): string {
    const lockedDate = dateConvertFromUTC(lock.locked).toLocaleDateString();
    const header = lock.lockType === LockTypes.Submission
      ? 'Submission locked on '
      : `Form ${lock.formInstanceId}: locked on `;

    const lockedBy = lock.lockedBy.email === this.appContext.loggedInUserEmail
      ? 'you'
      : `${lock.lockedBy.firstName} ${lock.lockedBy.lastName} (${lock.lockedBy.email})`;

    return header + `${lockedDate} by ${lockedBy}.`;
  }
}
